.main-content {
    position: relative;

    // Navbar
    .navbar-top {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    // Container
    .container-fluid {
        @include media-breakpoint-up(md) {
            padding-left: ($main-content-padding-x + $grid-gutter-width / 2) !important;
            padding-right: ($main-content-padding-x + $grid-gutter-width / 2) !important;
        }
    }
}

@-webkit-keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  @keyframes fadeinout {
    0%,100% { opacity: 0; }
    50% { opacity: 1; }
  }

.animate-logo {
    -webkit-animation: fadeinout 1.5s ease-in-out infinite;
    animation: fadeinout 1.5s ease-in-out infinite;
}

.colorsBox {
    flex-direction: row;
    flex: 1;
    display: flex;
    padding: 9.5px 0;
    border: 1px solid #dee2e6;
    height: 46px;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    justify-content: space-around;
}

.colorsBox div {
    height: 25px;
    width: 25px;
    cursor: pointer;
    background-size: 25px;
}

.colorsBox .active {
    border: 3px solid #A6CB68;
    box-shadow: 1px 1px 2px #b6e06b;
}

.materialThumb {
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.dropzone-single.dz-max-files-reached .dz-message:hover {
    background-color: #9ABB6166 !important;
}

.dropzone-single.dz-max-files-reached .dz-message {
    height: 100% !important;
}

.dz-button {
    color: '#333333' !important;
    padding: 4px !important;
    border-radius: 4px !important;
}
