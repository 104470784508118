
.fileinput {
  display: inline-block;
  margin-bottom: 9px; }
  .fileinput.text-center {
      text-align: center; }
  .fileinput .fa {
      font-size: 14px;
      margin-top: -6px;
      position: relative;
      top: 2px;
      margin-right: 4px; }
  .fileinput input[type="file"] {
      display: none; }
  
  .fileinput .form-control {
  display: inline-block;
  padding-top: 7px;
  padding-bottom: 5px;
  margin-bottom: 0;
  vertical-align: middle;
  cursor: text; }
  
  .fileinput .thumbnail {
    border: 2px solid #f4f4f4bb;
    border-radius: 8px;
    box-shadow: 0 0 2rem 0 rgba(98, 101, 101, 0.1);
    width: 200px;
    height: 200px;
  }
  .full-width{
      max-width: 100% !important;
  }
  .fileinput .thumbnail.img-circle {
      border-radius: 50%;
      max-width: 100px; }
  
  .fileinput .thumbnail > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
 }
  
  .fileinput .btn {
  vertical-align: middle; }
  
  .fileinput-exists .fileinput-new,
  .fileinput-new .fileinput-exists {
  display: none; }
  
  .fileinput-inline .fileinput-controls {
  display: inline; }
  
  .fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle; }
  
  .form-control .fileinput-filename {
  vertical-align: bottom; }
  
  .fileinput.input-group {
  display: table; }
  
  .fileinput.input-group > * {
  position: relative;
  z-index: 2; }
  
  .fileinput.input-group > .btn-file {
  z-index: 1; }
  
  .fileinput-new.input-group .btn-file,
  .fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0; }
  
  .fileinput-new.input-group .btn-file.btn-xs,
  .fileinput-new .input-group .btn-file.btn-xs,
  .fileinput-new.input-group .btn-file.btn-sm,
  .fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0; }
  
  .fileinput-new.input-group .btn-file.btn-lg,
  .fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0; }
  