//
// Utilities
//


@import "../core/utilities/backgrounds";

@import "../core/utilities/blurable";

@import "../core/utilities/floating";

@import "../core/utilities/helper";

@import "../core/utilities/image";

@import "../core/utilities/opacity";

@import "../core/utilities/overflow";

@import "../core/utilities/position";

@import "../core/utilities/shadows";

@import "../core/utilities/sizing";

@import "../core/utilities/spacing";

@import "../core/utilities/text";

@import "../core/utilities/transform";


.fixed-height-stats .table-responsive {
  height: 456px !important;
}

